import { Asset } from './asset';
import { CampaignResponse } from './helper-classes';
import { Insight } from './insight';

export class Campaign extends Asset {
  public id: string;
  public content_id: number;
  public name?: string;
  public description?: string;
  public trigger?: Insight;
  public trigger_id?: string;
  public frequency_days?: number;
  public enabled = false;
  public responses?: CampaignResponse[];
  public is_real_time = false;
  public campaign_type?: string;
  public minimum_app_version?: string;
  public push_notification_type?: string;
  public metadata_type?: string;
  public event_triggers: string[];
  public status?: string = 'INACTIVE';
  public batch_job_id?: string;
  public channel?: string;
  // Promotion data
  public promoted = false;
  public is_campaign_promotion_in_progress = false;
  public promoted_by: string;
  // Metadata
  public created_by?: string;
  public created_dttm?: number;
  public last_updated_dttm?: number;
  public last_updated_by?: string;

  public category?: any;
  public subcategory?: any;

  // Lifecycle values
  public start_date?: number;
  public end_date?: number;
  public schedule?: string; // How often the campaign is run
  public schedule_seconds?: number; // How often the campaign is run in seconds
  public rule?: string; // Cloudwatch event rule
  public next_run_time?: number = 0;
  public last_run_time?: number = 0;

  // Simple metrics
  public executions?: number = 0;
  public total_results?: number = 0;
  public total_duration?: number = 0;
  public total_interactions?: number = 0;
  public total_notifications?: number = 0;

  //Helper property used only by front-end
  public insight_name?: string = '';
  public local_created_dttm?: string = 'Time not provided.';
  public local_last_updated_dttm?: string = 'Time not provided.';
  public local_start_date?: string = 'Time not provided.';
  public local_end_date?: string = 'Time not provided.';
  public local_next_run_time?: string = 'Time not provided.';
  public local_last_run_time?: string = 'Time not provided.';

  constructor() {
    super();
  }

  public get label() {
    return ('' + this.id).padStart(3, '0') + ' - ' + this.name;
  }

  public static clone(campaign: Campaign) {
    const clonedCampaign: Campaign = new Campaign();
    Object.assign(clonedCampaign, campaign);
    clonedCampaign.name = 'Clone - ' + campaign.name;

    //resetting the metrics
    clonedCampaign.executions = 0;
    clonedCampaign.total_results = 0;
    clonedCampaign.total_duration = 0;
    clonedCampaign.total_interactions = 0;
    clonedCampaign.total_notifications = 0;
    clonedCampaign.promoted = false;
    clonedCampaign.promoted_by = '';
    clonedCampaign.is_campaign_promotion_in_progress = false;

    clonedCampaign.enabled = false;
    clonedCampaign.status = 'INACTIVE';
    clonedCampaign.last_run_time = null;
    clonedCampaign.next_run_time = null;

    delete clonedCampaign.id;

    //Obsolete - Will delete in future relase
    //clonedCampaign.trigger = Insight.copy(campaign.trigger);
    clonedCampaign.responses = Object.assign([], campaign.responses);

    return clonedCampaign;
  }

  public static copy(sourceCampaign: Campaign) {
    const targetCampaign = new Campaign();
    Object.assign(targetCampaign, sourceCampaign);
    targetCampaign.responses = Object.assign([], sourceCampaign.responses);

    return targetCampaign;
  }
}
