<nb-card
  accent="success"
  style="
    width: 90%;
    height: 90%;
    min-width: 700px;
    min-height: 460px;
    max-height: 800px;
    max-width: 1000px;
    margin: auto;
  "
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto" *ngIf="title === 'New'">
        <nb-icon icon="plus-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="title === 'Edit'">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="title === 'New'">Create New {{type |titlecase}} Source</div>
      <div class="col-auto mr-auto" *ngIf="title === 'Edit'">Edit {{type |titlecase}} Source</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="padding: 1.143em; margin: -1.143em">
      <strong class="text-uppercase">{{type |titlecase}} Source Details</strong>
      <hr />
      <form #sourceItemForm="ngForm">
        <div class="row">
          <div class="col">
            <label>ID<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <div class="input-box">
              <span class="prefix" *ngIf="title === 'New'">{{prefix()}}</span>
              <input
                id="sourceItemId"
                name="sourceItemId"
                nbInput
                fullWidth
                style="width: 100%"
                [(ngModel)]="sourceItem.id"
                #sourceItemId="ngModel"
                [disabled]="title === 'Edit'"
                required
                [pattern]="patternId"
              />
              <span class="sufix" *ngIf="title === 'New'">_dmc</span>
            </div>
            <div *ngIf="sourceItemId.errors?.pattern">
              <p class="error">
                ID not valid! ID allows only alphanumeric characters and underscore.
              </p>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Location<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input
              id="location"
              name="location"
              nbInput
              fullWidth
              placeholder="Location"
              style="width: 100%"
              [(ngModel)]="sourceItem.location"
              #location="ngModel"
              [pattern]="patternLocation"
              required
            />
            <div *ngIf="location.errors?.pattern">
              <p class="error">Location not valid! Quotes (' ") are not allowed.</p>
            </div>
            <div *ngIf="type === 'link' && (location.touched || location.dirty) && !sourceItem.location.includes('utm_source=')">
              <p class="warning">Missing "utm_source" query parameter. Will set default value: "app".</p>
            </div>
            <div *ngIf="type === 'link' && (location.touched || location.dirty) && !sourceItem.location.includes('utm_medium=')">
              <p class="warning">Missing "utm_medium" query parameter. Will set default value: "app".</p>
            </div>
            <div *ngIf="type === 'link' && (location.touched || location.dirty) && !sourceItem.location.includes('utm_campaign=')">
              <p class="warning">Missing optional "utm_campaign" query parameter. You can enter the value in the field below.</p>
            </div>
          </div>
        </div>
        <br />
        <div class="row" *ngIf="type === 'link' && (location.touched || location.dirty) && !sourceItem.location.includes('utm_campaign=')">
          <div class="col">
            <label>Campaign:</label>
          </div>
          <div class="col-9">
            <input
              id="campaign"
              name="campaign"
              nbInput
              fullWidth
              placeholder="Campaign"
              style="width: 100%"
              [(ngModel)]="sourceItemCampaign"
              #campaign="ngModel"
              [pattern]="patternCampaign"
            />
            <div *ngIf="campaign.errors?.pattern">
              <p class="error">Only lowercase letters and underscores are allowed.</p>
            </div>
          </div>
        </div>
        <br />
      </form>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="save()" [disabled]="!sourceItemForm.form.valid">Save</button>
      </div>

      <div class="p-2">
        <button nbButton (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
