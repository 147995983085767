<nb-card
  accent="success"
  style="width: 90%; height: 90%; min-width: 700px; max-height: 800px; max-width: 1600px; margin: auto"
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto" *ngIf="title === 'New'">
        <nb-icon icon="plus-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="title === 'Edit' || title === 'Clone'">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }} Campaign</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <form #campaignForm="ngForm">
        <strong class="text-uppercase">Campaign Details</strong>
        <hr />

        <div class="row">
          <div class="col">
            <label>Campaign Name<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input
              id="name"
              ngbAutofocus
              [(ngModel)]="campaign.name"
              class="form-control form-control-sm"
              type="text"
              placeholder="Campaign Name"
              name="name"
              #name="ngModel"
              required
              nbInput
              style="width: 100%"
              appValidRegex="^[a-zA-Z0-9][a-z\d\-_:\s]*$"
            />
            <div *ngIf="(name?.touched || name?.dirty) && name?.errors?.required" class="error">
              Name is a required field
            </div>
            <div *ngIf="name.errors?.validRegex" class="error">
              The first character must be alphanumeric, and up to 128 letters (uppercase and lowercase), numbers,
              hyphens, and underscores are allowed.
            </div>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Description:</label></div>
          <div class="col-9">
            <textarea id="description" [(ngModel)]="campaign.description" name="description" nbInput fullWidth rows="3">
            </textarea>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col">
            <label nbTooltip="Real-time campaigns are triggered by events (e.g., MissionCancelationEvent,
            MissionEndEvent) and do not require an Insight.">Real Time:</label></div>
          <div class="col-9">
            <input type="checkbox" [ngModel]="campaign.is_real_time" name="is_real_time" (change)="isRealTimeChanged($event)"/>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col">
            <label>Insight<span style="color: red">*</span>:</label>
          </div>
          <div class="col-6">
            <ng-select
              id="insight"
              name="insight"
              placeholder="Select Insight"
              [(ngModel)]="campaign.trigger_id"
              style="width: 105%"
              required
              #insight="ngModel"
              [disabled]="hideInsightSelect"
            >
              <ng-option *ngFor="let insight of insights" [value]="insight.id">{{ insight.name }}</ng-option>
            </ng-select>
            <div
              *ngIf="(insight?.touched || insight?.dirty) && insight?.errors?.required"
              class="error"
            >
              Insight Name is a required field
            </div>
          </div>
          <div class="col">
            <button
              *ngIf="campaign.trigger_id"
              nbButton
              size="medium"
              ghost
              status="info"
              (click)="editInsight(campaign.trigger_id)"
            >
              <nb-icon icon="external-link-outline" nbTooltip="Open Selected Insight"></nb-icon>
            </button>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Campaign Type:</label></div>
          <div class="col-9">
            <ng-select
              id="campaign_type"
              name="campaign_type"
              placeholder="Select Campaign Type"
              [(ngModel)]="campaign.campaign_type"
              style="width: 68%"
            >
              <ng-option *ngFor="let campaign of campaignTypes" [value]="campaign.value">{{ campaign.name }}</ng-option>
            </ng-select>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Event Triggers:</label></div>
          <div class="col-9">
            <tag-input
              [placeholder]="'Add new'"
              [secondaryPlaceholder]="'Add new Event Trigger'"
              [ngModel]="campaign.event_triggers"
              [ngModelOptions]="{ standalone: true }"
              ngDefaultControl
              (onRemove)="onItemRemoved($event)"
              (onAdd)="onItemAdded($event)"
            >
              <tag-input-dropdown
                [autocompleteItems]="items"
                [showDropdownIfEmpty]="true"
                [dynamicUpdate]="false"
                [appendToBody]="false"
              >
              </tag-input-dropdown>
            </tag-input>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Campaign category:</label></div>
          <div class="col-9">
            <ng-select
              id="campaign_category"
              name="campaign_category"
              placeholder="Select campaign category"
              [(ngModel)]="campaign.category"
              style="width: 68%"
            >
              <ng-option *ngFor="let campaignCategory of campaignCategories" [value]="campaignCategory.value">
                {{ campaignCategory.name }}
              </ng-option>
            </ng-select>
          </div>
          <br />
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Campaign subcategory:</label></div>
          <div class="col-9">
            <ng-select
              id="campaign_subcategory"
              name="campaign_subcategory"
              placeholder="Select campaign subcategory"
              [(ngModel)]="campaign.subcategory"
              style="width: 68%"
            >
              <ng-option *ngFor="let campaignSubCategory of campaignSubCategories" [value]="campaignSubCategory.value">
                {{ campaignSubCategory.name }}
              </ng-option>
            </ng-select>
          </div>
          <br />
        </div>
        <br />

        <strong class="text-uppercase">Push notifications</strong>
        <hr />
        <div class="row">
          <div class="col"><label>Push notifications:</label></div>
          <div class="col-9">
            <ng-select
              id="push_info"
              name="push_info"
              placeholder="Select push type"
              [(ngModel)]="campaign.push_notification_type"
              style="width: 68%"
            >
              <ng-option *ngFor="let pushType of pushNotificationTypes" [value]="pushType">
                {{ pushType | replaceunderscore | titlecase }}
              </ng-option>
            </ng-select>
          </div>
          <br />
        </div>
        <br />

        <div *ngIf="campaign.push_notification_type==='push_dynamic'" class="row">
          <div class="col"><label>Metadata type:</label></div>
          <div class="col-9">
            <ng-select
              id="metadata_type"
              name="metadata_type"
              placeholder="Select metadata type"
              [(ngModel)]="campaign.metadata_type"
              style="width: 68%"
            >
              <ng-option *ngFor="let metadataType of metadataTypes" [value]="metadataType.value">
                {{ metadataType.name | replaceunderscore | titlecase }}
              </ng-option>
            </ng-select>
          </div>
          <br />
        </div>
        <br />

        <strong class="text-uppercase">Content</strong>
        <hr />
        <div class="row">
          <div class="col">
            <label>Content Name<span style="color: red">*</span>:</label>
          </div>
          <div class="col-6">
            <ng-select
              name="content"
              placeholder="Select Content"
              [(ngModel)]="campaign.content_id"
              #content_id="ngModel"
              required
              style="width: 105%"
            >
              <ng-option *ngFor="let contentOption of content" [value]="contentOption.content_id">
                {{ contentOption.headline_text }}</ng-option
              >
            </ng-select>
            <div
              *ngIf="(content_id?.touched || content_id?.dirty) && content_id?.errors?.required"
              class="error"
            >
              Content Name is a required field
            </div>
          </div>
          <div class="col">
            <button
              *ngIf="campaign.content_id"
              nbButton
              size="medium"
              ghost
              status="info"
              (click)="editContent(campaign.content_id)"
            >
              <nb-icon icon="external-link-outline" nbTooltip="Open Selected Content"></nb-icon>
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Campaign Channel:</label>
          </div>
          <div class="col-9">
            <ng-select name="channel" placeholder="Select Channel" [(ngModel)]="campaign.channel" style="width: 68%">
              <ng-option *ngFor="let campaignChannel of campaignChannels" [value]="campaignChannel.value">
                {{ campaignChannel.name }}</ng-option
              >
            </ng-select>
          </div>
        </div>
        <br />

        <strong class="text-uppercase">Survey</strong>
        <hr />
        <div class="row">
          <div class="col"><label>Survey Name:</label></div>
          <div class="col-9">
            <ng-select
              [items]="surveys"
              [multiple]="true"
              [closeOnSelect]="false"
              [searchable]="false"
              bindLabel="name"
              placeholder="Select Surveys"
              [(ngModel)]="selectedSurvey"
              name="survey"
              style="width: 68%"
            >
            </ng-select>
          </div>
        </div>
        <br />

        <strong class="text-uppercase">Action Details</strong>
        <hr />

        <div class="row">
          <div class="col">
            <label>Frequency (Days)<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input
              id="frequency_days"
              name="frequency_days"
              placeholder="90"
              type="number"
              min="1"
              class="form-control form-control-sm"
              [(ngModel)]="campaign.frequency_days"
              #frequencyDays="ngModel"
              required
              nbInput
              style="width: 100%"
            />
            <div
              *ngIf="(frequencyDays?.touched || frequencyDays?.dirty) && frequencyDays?.errors?.required"
              class="error"
            >
              Frequency is a required field
            </div>
          </div>
        </div>
        <br />
      </form>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" [disabled]="!campaignForm.valid" (click)="save()">Save</button>
      </div>
      <div class="p-2">
        <button nbButton (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
