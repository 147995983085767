<div *ngIf="campaign">
  <nb-card
    accent="success"
    style="width: 80%; height: 80%; max-height: 750px; max-width: 1600px; margin: auto; margin-bottom: 10px"
  >
    <nb-card-header>
      <div class="row">
        <div class="col-auto mr-auto">
          <nb-icon icon="search-outline"></nb-icon>
        </div>
        <div class="col-auto mr-auto">Campaign ID: {{ campaign.id }}</div>
        <div class="col-auto">
          <button nbButton (click)="close()">X</button>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <h6 class="text-uppercase">Campaign Details</h6>
          <dl class="row small">
            <dt class="col-2">Name</dt>
            <dd *ngIf="!campaign_name_validator" class="col-10">
              {{ campaign.name }}
            </dd>
            <dd *ngIf="campaign_name_validator" class="col-10 error">
              {{ campaign.name }} (invalid campaign name)
            </dd>
            <dt class="col-2">Created By</dt>
            <dd *ngIf="campaign.created_by !== null; else noUser" class="col-10">
              {{ campaign.created_by }}
            </dd>
            <dt class="col-2">Created On</dt>
            <dd class="col-10">{{ formatted_created_time }}</dd>
            <dt class="col-2">Last Updated By</dt>
            <dd *ngIf="campaign.last_updated_by !== null; else noUser" class="col-10">
              {{ campaign.last_updated_by }}
            </dd>
            <dt class="col-2">Last Updated On</dt>
            <dd class="col-10">{{ formatted_updated_date }}</dd>
            <ng-template #noUser>
              <dd class="col-10">Unknown</dd>
            </ng-template>
            <dt class="col-2">Description</dt>
            <dd class="col-10">{{ campaign.description }}</dd>
            <dt *ngIf="campaign.promoted" class="col-2">Promoted</dt>
            <dd *ngIf="campaign.promoted" class="col-10">True</dd>
            <dt *ngIf="show_promoter_username" class="col-2">Promoted by</dt>
            <dd *ngIf="show_promoter_username" class="col-10">
              {{ campaign.promoted_by }}
            </dd>
            <dt *ngIf="campaign?.status" class="col-2">Status</dt>
            <dd *ngIf="campaign?.status" class="col-10">
              {{ campaign.status | titlecase }}
            </dd>
            <dt class="col-3" nbTooltip="Real-time campaigns are triggered by events (e.g., MissionCancelationEvent,
            MissionEndEvent) and do not require an Insight.">Real Time</dt>
            <dd class="col-9">
              <nb-checkbox status="info" [checked]="campaign.is_real_time" disabled></nb-checkbox>
            </dd>
          </dl>

          <ng-container *ngIf="!campaign.is_real_time">
            <h6 class="text-uppercase">Insight</h6>
            <hr />
            <dl class="row small">
              <dt class="col-3">Insight/Trigger ID</dt>
              <dd class="col-9">
                <a [routerLink]="[]" (click)="viewInsight(campaign.trigger_id)">{{ campaign.trigger_id }}</a>
              </dd>
            </dl>
          </ng-container>

          <h6 class="text-uppercase">Content</h6>
          <hr />
          <dl class="row small">
            <ng-container *ngIf="campaign.content_id !== null">
              <dt class="col-3">Content ID</dt>
              <dd class="col-9">
                <a [routerLink]="[]" (click)="viewContent(campaign.content_id)">{{ campaign.content_id }}</a>
              </dd>
            </ng-container>
          </dl>

          <h6 class="text-uppercase">Surveys</h6>
          <hr />
            <ng-container *ngFor="let survey of selectedSurvey" [value]="survey.id">

          <dl class="row">
              <dt class="col-2">Survey Name</dt>
              <dd  class="col-4">

                <a [routerLink]="[]" (click)="viewSurvey(survey)"> {{ survey.name}}</a>
              </dd>
              <dt class="col-2">Survey ID</dt>
              <dd  class="col-4">

                <a [routerLink]="[]" (click)="viewSurvey(survey)"> {{ survey.id}}</a>
              </dd>
            </dl>
            </ng-container>
            <ng-container *ngIf="selectedSurvey.length === 0">
            <dd class="col-9">There are no Surveys associated with this campaign</dd>
            </ng-container>

          <h6 class="text-uppercase">Action Details</h6>
          <hr />
          <dl class="row small">
            <dt class="col-3">Activated</dt>
            <dd class="col-9">
              <input type="checkbox" [ngModel]="campaign.enabled" disabled />
            </dd>
            <ng-container *ngIf="campaign.schedule_seconds !== null">
              <dt class="col-3">Schedule</dt>
              <dd class="col-9">{{ scheduleLabel }}</dd>
            </ng-container>
            <ng-container *ngIf="campaign.next_run_time !== null">
              <dt class="col-3">Next Run Time</dt>
              <dd class="col-9">{{ formatted_next_run_time }}</dd>
            </ng-container>
            <ng-container *ngIf="campaign.start_date !== null">
              <dt class="col-3">Start Time</dt>
              <dd class="col-9">{{ formatted_start_time }}</dd>
            </ng-container>
            <ng-container *ngIf="campaign.end_date !== null">
              <dt class="col-3">End Time</dt>
              <dd class="col-9">{{ formatted_end_time }}</dd>
            </ng-container>
            <ng-container *ngIf="campaign.frequency_days !== null">
              <dt class="col-3">Frequency</dt>
              <dd class="col-9">{{ campaign.frequency_days }} days</dd>
            </ng-container>
          </dl>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="d-flex flex-row-reverse">
        <div class="p-2">
          <button nbButton status="info" (click)="close()">Close</button>
        </div>
        <div *ngIf="display_promotion_button" class="p-2">
          <button nbButton [disabled]="!disable_promotion_button" status="info" (click)="promoteCampaign()">
            Promote
          </button>
        </div>
        <div *ngIf="display_management_buttons" class="p-2">
          <button nbButton status="success" (click)="activateCampaign()">Activate</button>
        </div>
        <div *ngIf="display_management_buttons" class="p-2">
          <button nbButton status="danger" (click)="deactivateCampaign()">Deactivate</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
