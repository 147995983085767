<nb-card
  accent="success"
  style="width: 90%; height: 90%; min-width: 700px; max-height: 800px; max-width: 1600px; margin: auto"
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }} Content</div>
      <div class="col-auto">
        <button
          nbSpinnerStatus="success"
          nbButton
          outline
          [disabled]="disableRefreshButton"
          status="info"
          shape="round"
          size="small"
          (click)="refresh()"
          nbTooltip="Refresh DRB IDs"
          style="margin-top: 15%"
        >
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
      </div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <!-- Those are general Content fields that are same for any type of payloard record [banner, MS, Contextual] -->
      <h6 class="text-uppercase">Content Details</h6>
      <form #contentForm="ngForm">
        <div class="row">
          <div class="col">
            <label>Category Icon Id<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <nb-select
              placeholder="Category Icon ID"
              [(ngModel)]="content.category_icon_id"
              fullWidth
              style="width: 90%"
              id="category_icon_id.id"
              name="content.category_icon_id"
              ngControl="categoryCtrl"
              required
              (selectedChange)="onSelectionChange($event)"
            >
              <nb-option *ngFor="let category of categoryIconId" [value]="category">
                {{ category | replaceunderscore | titlecase }}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col">
            <label>Category Title Text:</label>
          </div>
          <div class="col-9">
            <nb-select
              placeholder="Category Title"
              [(ngModel)]="content.category_title_text"
              fullWidth
              style="width: 90%"
              id="category_title_text"
              name="category_title_text"
              ngControl="categoryCtrl"
              #category_title_text="ngModel"
              (selectedChange)="onCategoryTitleSelect($event)"
            >
              <nb-option *ngFor="let category of availableCategories()" [value]="category.text">
                  {{ category.text | replaceunderscore | titlecase }}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Category Title ID:</label></div>
          <div class="col-9">
            <input
              nbInput
              placeholder="Category Title ID"
              [(ngModel)]="content.category_title_id"
              fullWidth
              style="width: 90%"
              id="category_title_id"
              name="category_title_id"
              ngControl="categoryCtrl"
              #category_title_id="ngModel"
              disabled
            />
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col">
            <label>Action Type<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <nb-select
              placeholder="Action Type"
              [(ngModel)]="content.action_type"
              fullWidth
              style="width: 90%"
              id="content.action_type"
              name="content.action_type"
              ngControl="categoryCtrl"
              required
              (ngModelChange)="onActionTypeChange()"
            >
              <nb-option *ngFor="let action of actionType" [value]="action">
                {{ action | replaceunderscore | titlecase }}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Display Type<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <nb-select
              placeholder="Display Type"
              [(ngModel)]="content.display_type"
              fullWidth
              style="width: 90%"
              id="content.display_type"
              name="content.display_type"
              ngControl="categoryCtrl"
              required
            >
              <nb-option *ngFor="let display of displayType" [value]="display">
                {{ display | replaceunderscore | titlecase }}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Min App Version:</label></div>
          <div class="col-9">
            <nb-select
              placeholder="Min App Version"
              [(ngModel)]="content.min_app_version"
              fullWidth
              style="width: 90%"
              id="min_app_version"
              name="min_app_version"
              ngControl="categoryCtrl"
              required
            >
              <nb-option *ngFor="let version of versions" [value]="version">
                {{ version }}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Headline Text:</label></div>
          <div class="col-9">
            <input
              nbInput
              placeholder="Headline Text"
              [(ngModel)]="content.headline_text"
              fullWidth
              style="width: 90%"
              id="headline_text"
              name="headline_text"
              ngControl="categoryCtrl"
            />
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col"><label>Headline Text ID:</label></div>
          <div class="col-9">
            <ng-select
              placeholder="Headline Text ID"
              [(ngModel)]="content.headline_text_id"
              style="width: 90%"
              id="headline_text_id"
              name="headline_text_id"
              ngControl="categoryCtrl"
              #headline_text_id="ngModel"
              [addTag]="addTag"
            >
              <ng-option *ngFor="let drbID of getDrbIdsByType('string')" [value]="drbID" >
                  {{ drbID }}
              </ng-option>
            </ng-select>
        </div>
    </div>
        <br />

        <div class="row">
          <div class="col"><label>Message Type:</label></div>
          <div class="col-9">
            <ng-select
              ngDefaultControl
              id="message_type"
              name="message_type"
              placeholder="Select Message Type"
              multiple="true"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="message_type"
              style="width: 90%"
              (add)="messageTypeAdd($event)"
              (remove)="messageTypeRemove($event)"
              (clear)="messageTypeClear()"
            >
              <ng-option *ngFor="let message of messageType" [value]="message">{{
                message | replaceunderscore | titlecase
              }}</ng-option>
            </ng-select>
          </div>
        </div>
        <br />
        <!-- Dynamic MCA push notification fields -->
        <div class="row">
          <div class="col"><label>Push Title String ID:</label></div>
          <div class="col-9">
            <ng-select
              placeholder="Push Title String ID"
              [(ngModel)]="content.push_title_string_id"
              style="width: 90%"
              id="push_title_string_id"
              name="push_title_string_id"
              ngControl="categoryCtrl"
              #push_title_string_id="ngModel"
              [addTag]="addTag"
            >
              <ng-option *ngFor="let drbID of getDrbIdsByType('string')" [value]="drbID" >
                  {{ drbID }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col"><label>Push String ID:</label></div>
          <div class="col-9">
            <ng-select
              placeholder="Push String ID"
              [(ngModel)]="content.push_string_id"
              style="width: 90%"
              id="push_string_id"
              name="push_string_id"
              ngControl="categoryCtrl"
              #push_string_id="ngModel"
              [addTag]="addTag"
            >
              <ng-option *ngFor="let drbID of getDrbIdsByType('string')" [value]="drbID" >
                  {{ drbID }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <br />
        <hr />

        <div class="row">
          <div class="col">
            <label>Action Button </label>
          </div>
          <div class="col-9">
            <nb-checkbox
              ngDefaultControl
              (checkedChange)="actionButtonEvent($event)"
              [(ngModel)]="action_button_enabled"
              name="action_button_enabled"
              [ngModelOptions]="{ standalone: true }"
            ></nb-checkbox>
          </div>
        </div>

        <div *ngIf="action_button_enabled">
          <div class="row">
            <div class="col">
              <label>Button text:</label>
            </div>
            <div class="col-9">
              <input
                nbInput
                placeholder="Text"
                [(ngModel)]="content.action_button[0].text"
                fullWidth
                style="width: 90%"
                id="action_button_text_id"
                name="action_button_text_id"
                ngControl="categoryCtrl"
              />
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col">
              <label>Button text ID:</label>
            </div>
            <div class="col-9">
              <ng-select
                      placeholder="Text ID"
                      [(ngModel)]="content.action_button[0].text_id"
                      style="width: 90%"
                      id="action_button_text_id_id"
                      name="action_button_text_id_id"
                      ngControl="categoryCtrl"
                      #action_button_text_id_id="ngModel"
                      [addTag]="addTag"
              >
                <ng-option *ngFor="let drbID of getDrbIdsByType('string')" [value]="drbID">
                  {{ drbID }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col">
              <label>Destination URI:</label>
            </div>
            <div class="col-9">
              <input
                nbInput
                placeholder="Destination URI"
                [(ngModel)]="content.action_button[0].destination_uri"
                fullWidth
                style="width: 90%"
                id="action_button_destination_uri"
                name="action_button_destination_uri_id"
                ngControl="categoryCtrl"
              />
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col">
              <label>Destination URI ID:</label>
            </div>
            <div class="col-9">
              <ng-select
                      placeholder="Destination URI ID"
                      [(ngModel)]="content.action_button[0].destination_uri_id"
                      [ngModelOptions]="{ standalone: true }"
                      style="width: 90%"
                      id="destination_uri_id"
                      name="destination_uri_id"
                      ngControl="categoryCtrl"
                      #destination_uri_id="ngModel"
                      [addTag]="addTag"
              >
                <ng-option *ngFor="let drbID of getDrbIdsByType('link')" [value]="drbID">
                  {{ drbID }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col">
              <label>Destination Type <span style="color: red">*</span>:</label>
            </div>
            <div class="col-9">
              <nb-select
                required
                placeholder="Select destination type"
                [(ngModel)]="content.action_button[0].destination_type"
                id="destination_type"
                name="destination_type"
                #destination_type="ngModel"
                fullWidth
                style="width: 90%"
              >
                <nb-option *ngFor="let destination_type of destinationTypes" [value]="destination_type.value">
                  {{ destination_type.label | replaceunderscore | titlecase }}
                </nb-option>
              </nb-select>
              <div
                *ngIf="(destination_type?.touched || destination_type?.dirty) && destination_type?.errors?.required"
                class="error"
              >
                Destination Type is a required field
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <!-- Inside NG Template are fields from Payload Records [Array of paylod record] banner, MS, Contextual -->
        <ng-template ngFor let-i="index" let-payload [ngForOf]="content.payload_records">
          <nb-card accent="success" style="width: 600px; margin: auto">
            <nb-card-header>
              <div class="row">
                <div *ngIf="payload.payload_record_type !== 'banner'" class="col-auto mr-auto">
                  {{ payload.payload_record_type.split('_').join(' ') | titlecase }}
                </div>
                <div *ngIf="payload.payload_record_type === 'banner'" class="col-auto mr-auto">
                  {{ payload.payload_record_type | titlecase }}
                </div>
              </div>
            </nb-card-header>
            <nb-card-body>
              <div *ngIf="payload.payload_record_type === 'contextual_message'">
                <div class="row">
                  <div class="col">
                    <label>Active Parent ID<span style="color: red">*</span>:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      placeholder="Active Parent ID"
                      [(ngModel)]="content.active_parent_uri_id"
                      fullWidth
                      style="width: 90%"
                      id="active_parent_uri_id"
                      name="active_parent_uri_id"
                      [ngModelOptions]="{ standalone: true }"
                      required
                      #active_parent_uri_id="ngModel"
                    />
                    <div
                      *ngIf="
                        (active_parent_uri_id?.touched || active_parent_uri_id?.dirty) &&
                        active_parent_uri_id?.errors?.required
                      "
                      class="error"
                    >
                      Active parent uri id is a required field
                    </div>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Action Display:</label>
                  </div>
                  <div class="col-9">
                    <ng-select
                      ngDefaultControl
                      id="action_display"
                      name="action_display"
                      placeholder="Select Action Display"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.action_display"
                      fullWidth
                      style="width: 90%"
                      #action_display[i]="ngModel"
                      [clearable]="false"
                    >
                      <div *ngIf="payload.payload_record_type === 'contextual_message'">
                        <ng-option
                          *ngFor="let actionDisplay of actionDisplays | slice: 0:2"
                          [value]="actionDisplay.value"
                          >{{ actionDisplay.label | splitWordUppercase }}</ng-option
                        >
                      </div>
                      <div *ngIf="!['banner', 'personalized_offer'].includes(payload.payload_record_type)">
                        <ng-option
                          *ngFor="let actionDisplay of actionDisplays | slice: 2:3"
                          [value]="actionDisplay.value"
                          >{{ actionDisplay.label | splitWordUppercase }}</ng-option
                        >
                      </div>
                      <div *ngIf="payload.payload_record_type === 'personalized_offer'">
                        <ng-option
                          *ngFor="let actionDisplay of actionDisplays | slice: -1"
                          [value]="actionDisplay.value"
                          >{{ actionDisplay.label | splitWordUppercase }}</ng-option
                        >
                      </div>
                    </ng-select>
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Content Text:</label>
                  </div>
                  <div class="col-9">
                    <textarea
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.content_text"
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Content text"
                      [name]="content_text"
                      #content_text[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    ></textarea>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Content Text ID:</label>
                  </div>
                  <div class="col-9">
                    <ng-select
                            placeholder="Content Text ID"
                            [(ngModel)]="payload.content_text_id"
                            [ngModelOptions]="{ standalone: true }"
                            fullWidth
                            style="width: 90%"
                            id="content_text_id"
                            name="content_text_id"
                            ngControl="categoryCtrl"
                            #content_text_id[i]="ngModel"
                            [addTag]="addTag"
                    >
                      <ng-option *ngFor="let drbID of getDrbIdsByType('string')" [value]="drbID">
                        {{ drbID }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Content Text Display Order:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.context_text_display_order"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Context Text Display Order"
                      [name]="context_text_display_order"
                      #context_text_display_order[i]="ngModel"
                      (keypress)="keyPressNumbers($event)"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div *ngIf="!['banner', 'personalized_offer'].includes(payload.payload_record_type)">
                <div class="row">
                  <div class="col">
                    <label>Video ID:</label>
                  </div>
                  <div class="col-9">
                    <ng-select
                            placeholder="Video ID"
                            [(ngModel)]="payload.video_id"
                            [ngModelOptions]="{ standalone: true }"
                            fullWidth
                            style="width: 90%"
                            id="video_id"
                            name="video_id"
                            ngControl="categoryCtrl"
                            #video_id[i]="ngModel"
                            [addTag]="addTag"
                            (keypress)="keyPressNumbers($event)"
                    >
                      <ng-option *ngFor="let drbID of getDrbIdsByType('video')" [value]="drbID">
                        {{ drbID }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="!['banner', 'personalized_offer'].includes(payload.payload_record_type)">
                <div class="row">
                  <div class="col">
                    <label>Video URL Display Order:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.video_url_display_order"
                      class="form-control form-control-lg"
                      type="text"
                      (keypress)="keyPressNumbers($event)"
                      placeholder="Video URL Display Order"
                      [name]="video_url_display_order"
                      #video_url_display_order[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Content HTML:</label>
                  </div>
                  <div class="col-9">
                    <textarea
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.content_html"
                      class="form-control form-control-sm"
                      type="rich_text"
                      placeholder="Content HTML"
                      [name]="content_html"
                      #content_html[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    ></textarea>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Content HTML ID:</label>
                  </div>
                  <div class="col-9">
                    <ng-select
                            placeholder="Content HTML ID"
                            [(ngModel)]="payload.content_html_id"
                            [ngModelOptions]="{ standalone: true }"
                            fullWidth
                            style="width: 90%"
                            id="content_html_id"
                            name="content_html_id"
                            ngControl="categoryCtrl"
                            #content_html_id[i]="ngModel"
                            [addTag]="addTag"
                    >
                      <ng-option *ngFor="let drbID of getDrbIdsByType('string')" [value]="drbID">
                        {{ drbID }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Content HTML Display Order:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.content_html_display_order"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Content HTML Display Order"
                      [name]="content_html_display_order"
                      #content_html_display_order[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Image ID:</label>
                  </div>
                  <div class="col-9">
                    <ng-select
                        placeholder="Image ID"
                        [(ngModel)]="payload.image_id"
                        [ngModelOptions]="{ standalone: true }"
                        fullWidth
                        style="width: 90%"
                        id="image_id"
                        name="image_id"
                        ngControl="categoryCtrl"
                        #image_id[i]="ngModel"
                        [addTag]="addTag"
                    >
                      <ng-option *ngFor="let drbID of getDrbIdsByType('image')" [value]="drbID">
                        {{ drbID }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Image URL:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.image_url"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Image URL"
                      [name]="image_url"
                      #image_url[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Image Display Order:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.image_display_order"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Image Display Order"
                      [name]="image_display_order"
                      #image_display_order[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Webview URL:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.webview_url"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Webview URL"
                      [name]="webview_url"
                      #webview_url[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Webview URL ID:</label>
                  </div>
                  <div class="col-9">
                    <ng-select
                        placeholder="Webview URL ID"
                        [(ngModel)]="payload.webview_url_id"
                        [ngModelOptions]="{ standalone: true }"
                        fullWidth
                        style="width: 90%"
                        id="webview_url_id"
                        name="webview_url_id"
                        ngControl="categoryCtrl"
                        #webview_url_id[i]="ngModel"
                        [addTag]="addTag"
                    >
                      <ng-option *ngFor="let drbID of getDrbIdsByType('link')" [value]="drbID">
                        {{ drbID }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Webview URL Display Order:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.webview_url_display_order"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Webview URL Display Order"
                      [name]="webview_url_display_order"
                      #webview_url_display_order[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div [hidden]="!isScriptedNavigationEnabled">
                  <div class="row">
                    <div class="col">
                      <label>Script ID:</label>
                    </div>
                    <div class="col-9">
                      <ng-select
                        placeholder="Script ID"
                        [(ngModel)]="payload.script_id"
                        [ngModelOptions]="{ standalone: true }"
                        fullWidth
                        style="width: 90%"
                        id="scripted_navigation_id"
                        name="scripted_navigation_id"
                        ngControl="categoryCtrl"
                        #scripted_navigation_id[i]="ngModel"
                        [addTag]="addTag"
                    >
                      <ng-option *ngFor="let drbID of getDrbIdsByType('file')" [value]="drbID">
                        {{ drbID }}
                      </ng-option>
                    </ng-select>
                    </div>
                  </div>
                  <br />
                </div>
              </div>

              <div *ngIf="payload.payload_record_type !== 'banner'">
                <div [hidden]="!isScriptedNavigationEnabled">
                  <div class="row">
                    <div class="col"><label>Script ID Display Order:</label></div>
                    <div class="col-9">
                      <input
                        nbInput
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="payload.script_id_display_order"
                        class="form-control form-control-lg"
                        type="text"
                        placeholder="Script ID Display Order"
                        [name]="script_id_display_order"
                        #scripted_navigation_id[i]="ngModel"
                        fullWidth
                        style="width: 90%"
                      />
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              
              <!-- Banner specific -->
              <div *ngIf="payload.payload_record_type === 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Banner ID:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.banner_id"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Banner ID"
                      [name]="banner_id"
                      #banner_id[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type === 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Banner Text: </label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.banner_text"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Banner Text"
                      [name]="banner_text"
                      #banner_text[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type === 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Disclaimers Text:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.disclaimers_text"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Disclaimers Text"
                      [name]="disclaimers_text"
                      #disclaimers_text[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type === 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Product URL:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.product_url"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Product URL"
                      [name]="product_url"
                      #product_url[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type === 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Banner Image ID:</label>
                  </div>
                  <div class="col-9">
                    <ng-select
                        placeholder="Banner Image ID"
                        [(ngModel)]="payload.banner_image_id"
                        [ngModelOptions]="{ standalone: true }"
                        fullWidth
                        style="width: 90%"
                        id="banner_image_id"
                        name="banner_image_id"
                        ngControl="categoryCtrl"
                        #banner_image_id[i]="ngModel"
                        [addTag]="addTag"
                    >
                      <ng-option *ngFor="let drbID of getDrbIdsByType('image')" [value]="drbID">
                        {{ drbID }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <br />
              </div>

              <div *ngIf="payload.payload_record_type === 'banner'">
                <div class="row">
                  <div class="col">
                    <label>Source Code:</label>
                  </div>
                  <div class="col-9">
                    <input
                      nbInput
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="payload.source_code"
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Source Code"
                      [name]="source_code"
                      #source_code[i]="ngModel"
                      fullWidth
                      style="width: 90%"
                    />
                  </div>
                </div>
                <br />
              </div>
              <!--  -->
            </nb-card-body>
            <nb-card-footer *ngIf="payload.payload_record_type !== 'banner'">
              <div class="d-flex flex-row-reverse">
                <div class="p-2">
                  <button nbButton size="small" status="info" (click)="previewAlert(payload.content_html)">
                    Preview HTML
                  </button>
                </div>
              </div>
            </nb-card-footer>
          </nb-card>
          <br />
          <hr />
        </ng-template>
      </form>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" [disabled]="!contentForm.valid || !shouldButtonEnabled()" (click)="save()">
          Save
        </button>
      </div>
      <div class="p-2">
        <button nbButton (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
