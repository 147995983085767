import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { LoadSmartCleanRecords, PatchCleanScore, RemoveCleanScore } from '../redux/clean-score.actions';
import { CleanScoreState } from '../redux';
import { Observable } from 'rxjs';
import { SmartCleanService } from 'src/app/core/services/smart-clean.service';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';

@Component({
  selector: 'app-create-clean-score',
  templateUrl: './create-clean-score.component.html',
  styleUrls: ['./create-clean-score.component.scss']
})
export class CreateCleanScoreComponent implements OnInit, AfterViewInit {
  @Select(CleanScoreState.smartCleanRecords) public data$: Observable<any>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public Object = Object;
  public spin;
  public cleanScoreRecords = [];
  public selectedMaps: Record<string, any[]> = {};
  public enableSubmit = false;
  public selectedRobots: string[] = [];
  public loadedRobots: string[] = [];

  constructor(private store: Store, private service: SmartCleanService) {}

  public ngAfterViewInit(): void {
    this.robotIdClear();
  }

  public ngOnInit(): void {
    this.data$.subscribe((data) => {
      if (data.length > 0) {
        this.service.populateFields(data, this.cleanScoreRecords);
        this.loadedRobots = this.selectedRobots;
      }
    });
    this.loading.subscribe((data) => (this.spin = data));
  }

  public onSubmit(): void {
    const recordKeys = this.Object.keys(this.cleanScoreRecords);
    recordKeys.forEach((recordKey) => {
      const scoreKeys = this.Object.keys(this.cleanScoreRecords[recordKey]);
      scoreKeys.forEach((scoreKey) => {
        this.store.dispatch(new PatchCleanScore(this.cleanScoreRecords[recordKey][scoreKey]));
      });
    });
  }
  public onSelectionChange() {
    this.enableSubmit = Object.values(this.selectedMaps).some((arr) => arr.length > 0);
  }
  public robotIdRemove(event) {
    delete this.cleanScoreRecords[event.label];
    delete this.selectedMaps[event.label];
    this.loadedRobots = this.selectedRobots;
  }

  public robotIdAdd(event) {
    this.store.dispatch(new LoadSmartCleanRecords(event.label));
  }

  public robotIdClear() {
    this.store.dispatch(new RemoveCleanScore());
    this.cleanScoreRecords = [];
    this.selectedMaps = {};
    this.loadedRobots = [];
    this.selectedRobots = [];
  }
}
