<app-spinner *ngIf="spin"></app-spinner>
<div style="min-height: 550px">
  <nb-card>
    <nb-card-header>
      <h3 style="position: relative">Clean Score</h3>
    </nb-card-header>
    <nb-card-body>
      <div>
        <div class="row">
          <div>
            <h5>Robot ID:</h5>
          </div>
        </div>
        <!-- Robot selection -->
        <div class="row">
          <div class="col-9">
            <ng-select
              class="robotid"
              ngDefaultControl
              id="robot_id"
              name="robot_id"
              placeholder="Insert robot ids"
              multiple="true"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="selectedRobots"
              [addTag]="true"
              [selectOnTab]="true"
              [isOpen]="false"
              style="width: 68%"
              (add)="robotIdAdd($event)"
              (remove)="robotIdRemove($event)"
              (clear)="robotIdClear()"
            >
            </ng-select>
          </div>
        </div>
        <br />
        <div *ngIf="Object.keys(this.cleanScoreRecords).length > 0">
          <div *ngFor="let robot of loadedRobots">
            <div class="row">
              <h6>Select Map id for {{ robot.label }}:</h6>
            </div>
            <ng-select
              fullWidth
              [(ngModel)]="selectedMaps[robot.label]"
              [multiple]="true"
              (change)="onSelectionChange()"
              placeholder="Select Map IDs"
            >
              <ng-option *ngFor="let p of Object.keys(this.cleanScoreRecords[robot.label])" [value]="p">
                {{ p }}
              </ng-option>
            </ng-select>

            <br />
            <div *ngFor="let map of selectedMaps[robot.label]">
              <div class="row">
                <p><strong>Map ID: </strong>{{ map }}</p>
              </div>
              <div *ngFor="let region of this.cleanScoreRecords[robot.label][map].regions; let i = index">
                <div class="row">
                  <h6 class="bold-head">Region Id: {{ region.region_id }} - score:</h6>
                  <div class="col">
                    <input
                      nbInput
                      min="0.0"
                      max="1.1"
                      step="0.1"
                      [value]="region.clean_score"
                      type="number"
                      [(ngModel)]="region.clean_score"
                    />
                  </div>
                </div>

                <br />
              </div>
            </div>

            <br />
            <hr />
          </div>
        </div>
        <br />

        <div *ngIf="enableSubmit" style="margin-top: 10px">
          <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
