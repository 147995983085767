import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appValidRobotIdRegex]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RobotIdValidatorDirective, multi: true }]
})
export class RobotIdValidatorDirective implements Validator {
  @Input() public appValidRobotIdRegex!: string;

  public validate(control: AbstractControl): { [key: string]: any } | null {
    const regex = new RegExp(this.appValidRobotIdRegex);

    return regex.test(control.value) ? null : { validRobotIdRegex: { valid: false } };
  }
}


