// Modules
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbAccordionModule,
  NbAlertModule, NbAutocompleteModule,
  NbButtonModule,
  NbCalendarModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbRouteTabsetModule,
  NbSelectModule,
  NbTabsetModule, NbTagModule,
  NbTimepickerModule
} from '@nebular/theme';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ValidatorDirective } from 'src/app/shared/validation/validation_canned_korz_cumul';
import { RobotIdValidatorDirective } from 'src/app/shared/validation/validation_robot_id_regex';
import { TimelineNotificationsComponent } from './timeline-notifications/timeline-notifications.component';
import { QaTestingComponent } from './qa-tool/qa-testing.component';
import { MyRobotsComponent } from './my-robots/my-robots.component';
import { QaCampaignComponent } from './forms/qa-campaign/qa-campaign.component';
import { routedComponents } from './qa-test-routing.module';
import { CannedKozrsComponent } from './canned_kozrs/canned-kozrs.component';
import { EditCannedKozrComponent } from './canned_kozrs/edit-canned-kozr/edit-canned-kozr.component';
import { ViewCannedKozrComponent } from './canned_kozrs/view-canned-kozr/view-canned-kozr.component';
import { ViewStatsComponent } from './canned_kozrs/view-stats/view-stats.component';
import { CleanTimeEstimatesSubComponent } from './clean-time-estimates/clean-time-estimates-sub/clean-time-estimates-sub.component';
import { CleanTimeEstimatesComponent } from './clean-time-estimates/clean-time-estimates.component';
import { EditHtaRecommendationComponent } from './hta-recommendation/edit-hta-recommendation/edit-hta-recommendation.component';
import { HtaRecommendationComponent } from './hta-recommendation/hta-recommendation.component';
import { ViewHtaRecommendationComponent } from './hta-recommendation/view-hta-recommendation/view-hta-recommendation.component';
import { ViewStatsHtaComponent } from './hta-recommendation/view-stats-hta/view-stats-hta.component';
import { EditMyRobotsComponent } from './my-robots/edit-my-robots/edit-my-robots.component';
import { ViewMyRobotsComponent } from './my-robots/view-my-robots/view-my-robots.component';
import { CommonBotsComponent } from './qa-tool/common-bots/common-bots.component';
import { KozrBotsComponent } from './qa-tool/kozr-bots/kozr-bots.component';
import { RobotHealthDashboardComponent } from './robot-health-dashboard/robot-health-dashboard.component';
import { RtpTestingComponent } from './rtp-testing/rtp-testing.component';
import { TimelineCommonBotsComponent } from './timeline-notifications/timeline-common-bots/timeline-common-bots.component';
import { TimelineKozrBotsComponent } from './timeline-notifications/timeline-kozr-bots/timeline-kozr-bots.component';
import { TimelineMyBotsComponent } from './timeline-notifications/timeline-my-bots/timeline-my-bots.component';
import { QaImageFormComponent } from './forms/qa-image-form/qa-image-form.component';
import { MatDatepickerModule } from '@matheo/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ViewCleanScoreComponent } from './clean-score/view-clean-score/view-clean-score.component';
import { CreateCleanScoreComponent } from './clean-score/create-clean-score/create-clean-score.component';
import { MessMakersComponent } from './mess-makers/mess-makers.component';

const COMPONENTS = [
  QaTestingComponent,
  CommonBotsComponent,
  KozrBotsComponent,
  //
  QaCampaignComponent,
  //
  MyRobotsComponent,
  EditMyRobotsComponent,
  ViewMyRobotsComponent,
  //
  RtpTestingComponent,
  TimelineNotificationsComponent,
  //
  HtaRecommendationComponent,
  ViewHtaRecommendationComponent,
  EditHtaRecommendationComponent,
  ViewStatsHtaComponent,

  CannedKozrsComponent,
  ViewStatsComponent,
  ViewCannedKozrComponent,
  EditCannedKozrComponent,
  TimelineCommonBotsComponent,
  TimelineKozrBotsComponent,
  TimelineMyBotsComponent,
  CleanTimeEstimatesComponent,
  CleanTimeEstimatesSubComponent,
  ViewCleanScoreComponent,
  CreateCleanScoreComponent,
  MessMakersComponent
];

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    SharedModule,
    CommonModule,
    CoreModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NbDialogModule,
    NbAccordionModule,
    NbCardModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbCalendarModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbAlertModule,
    NbTimepickerModule,
    NbTagModule,
    NbAutocompleteModule
  ],
  declarations: [
    ...routedComponents,
    ...COMPONENTS,
    CommonBotsComponent,
    KozrBotsComponent,
    RobotHealthDashboardComponent,
    ValidatorDirective,
    RobotIdValidatorDirective,
    QaImageFormComponent
  ]
})
export class QaTestingModule {}
