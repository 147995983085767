import { Component, DoCheck, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Robot } from 'src/app/models/robot';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { PostRobots, QaState } from '../../redux';

@Component({
  selector: 'app-edit-my-robots',
  templateUrl: './edit-my-robots.component.html',
  styleUrls: ['./edit-my-robots.component.css']
})
export class EditMyRobotsComponent implements OnInit, DoCheck {
  @Select(QaState.robots) public data_robots$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public robot: Robot;
  public title: string;
  public allRobots;
  public owner: string; // was input

  public seriesList = ['600', '800', '900', 'e', 'i', 'm', 'q', 's', 'j', 'c'];
  public families = ['Roomba', 'Braava', 'Terra'];
  public timezones = ['America/Chicago', 'America/Los_Angeles', 'America/New_York']; // TODO: add all of the options
  public regDate;
  public errorMsg = '';
  public isEdit = false;
  public dateForDatepickerStart: Date;

  constructor(
    private store: Store,
    protected ref: NbDialogRef<EditMyRobotsComponent>,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  public ngOnInit(): void {
    this.data_robots$.subscribe((data) => (this.allRobots = data));
    this.data_username$.subscribe((data) => (this.owner = data));

    /*
     * It is neccessary to remove the current robot from the list so that
     * ID verification can take place.
     */
    this.allRobots = this.allRobots.filter((robot) => robot.id !== this.robot.id);
    if (this.robot.registration_date) {
      this.dateForDatepickerStart = new Date(this.robot.registration_date * 1000);
    } else {
      this.dateForDatepickerStart = new Date();
    }
  }

  public ngDoCheck() {
    if (this.title.includes('Edit')) {
      this.isEdit = true;
    }
  }

  public uniqueRobotID(): boolean {
    return this.allRobots.filter((robot) => robot.id === this.robot.id).length === 0;
  }

  public save() {
    if (this.uniqueRobotID()) {
        this.robot.registration_date = parseInt((new Date(this.dateForDatepickerStart).getTime() / 1000).toFixed(0), 10);
        this.robot.owner = this.owner;
        this.store.dispatch(new PostRobots(this.robot, this.isEdit));
        this.ref.close();
    } else {
        this.toastrService.show(status, 'Robot ID has to be unique!', { status: 'danger' });
    }
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }
}
